<template>
	<PaddingContainer
		:component="SectionBlock"
		class="koller-section"
		v-bind="props"
		:padding="padding"
		:use-fallback-container="false"
	>
		<Component
			:is="component"
			v-for="({ component, props: componentProps }, index) in components"
			:key="component.name || index"
			v-bind="componentProps"
		/>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type {
	KollerSection,
	ScreenSize
} from '@SHARED/core/entities/sections/KollerSection';
import type { FlexOrientation } from '@SHARED/utils/helperTypes';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import SectionBlock from '@/components/blocks/SectionBlock.vue';
import KollerTextSubsection from '@/components/sections/KollerSection/KollerTextSubsection.vue';
import KollerSectionImage from '@/components/sections/KollerSection/KollerSectionImage.vue';

defineOptions({ name: 'KollerSection' });

const props = defineProps<KollerSection['config']>();

type SubComponent = {
	component: Component;
	props: Record<string, unknown>;
};

const kollerTextSubsectionComponent = markRaw(KollerTextSubsection);
const kollerSectionImageComponent = markRaw(KollerSectionImage);

const components = computed<[SubComponent, SubComponent]>(() => {
	const image = {
		component: kollerSectionImageComponent,
		props: props.image
	};

	const textSubsection = {
		component: kollerTextSubsectionComponent,
		props
	};

	return [image, textSubsection];
});

function getDirection(screenSize: ScreenSize): FlexOrientation {
	const isReverse = !props.placeImageFirst?.[screenSize];

	const fallbackDirection = screenSize === 'desktop' ? 'row' : 'column';

	const direction = props.contentDirection?.[screenSize] || fallbackDirection;

	return isReverse ? `${direction}-reverse` : direction;
}

const mobileSectionDirection = computed(() => getDirection('mobile'));

const desktopSectionDirection = computed(() => getDirection('desktop'));

const mobileSectionGap = computed(
	() => props.gapBetweenImageAndText?.mobile || 0
);
const desktopSectionGap = computed(
	() => props.gapBetweenImageAndText?.desktop || 0
);
</script>

<style lang="scss" scoped>
.koller-section {
	display: flex;
	gap: v-bind(mobileSectionGap);
	flex-direction: v-bind(mobileSectionDirection);

	@include screen-up(lg) {
		gap: v-bind(desktopSectionGap);
		flex-direction: v-bind(desktopSectionDirection);
	}
}
</style>
